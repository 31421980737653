.section-3 {
  background-color: rgb(245, 245, 245);
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  z-index: -2;
}

.section-3--container {
  min-width: 20vw;
  margin-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-3--h2 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 40px;
}

.section-3--wrapper {
  margin: 10px 0 30px;
  padding: 10px 10px 10px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.section-3--container h1 {
  font-size: 25px;
  font-weight: 100;
  padding: 5px;
}
.section-3--container p {
  font-size: 19px;
  font-weight: 100;
  padding: 5px;
}

.section-3--container a {
  text-decoration: none;
  padding-bottom: 5px;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.section-3--container a:hover {
  border-bottom: 1px solid rgb(94, 90, 90);
  transition: 1s;
}

@media screen and (max-width: 768px) {
  .section-3--container {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }
  .section-3--wrapper {
    align-items: center;
    padding: 10px;
  }
  .section-3--h2 {
    margin-top: 60px;
    font-size: 25px;
  }
}
