.section-1 {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  position: relative;
  z-index: -1;
  padding-bottom: 40px;
}

.section-1--container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-1--container > h2 {
  font-weight: 400;
  padding: 20px;
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.logos {
  display: flex;
  flex-direction: column;
}

.logos-item-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos-item-row img:nth-child(2) {
  transition-delay: 200ms;
}

.logos-item-row img:nth-child(3) {
  transition-delay: 400ms;
}

.logos-item-row img:nth-child(4) {
  transition-delay: 600ms;
}

.logos-item-row img {
  padding: 5px;
  width: 11em;
  filter: grayscale(100%);
}

@media screen and (max-width: 768px) {
  .section-1 {
    padding: 30px 0 40px;
  }
  .logos-item-row img {
    width: 6.5em;
  }
  .logos-item-row {
    flex-wrap: wrap;
  }
  .section-1--container > h2 {
    padding: 25px 0 25px 0;
    margin-top: 10px;
    font-size: 25px;
  }
}

@media screen and (max-width: 280px) {
  .logos-item-row img {
    width: 5em;
  }
  .section-1--container > h2 {
    padding: 5px 0 0 0;
    font-size: 22px;
  }
}
