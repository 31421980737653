.portfolio-gallery {
  min-height: 100vh;
  max-width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 10px 30px 10px;
}

.video-section {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.portfolio-gallery-container {
  max-width: 1200px;
  /* width: 70%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 30px 20px;
  margin: 0 30px 40px 30px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  box-shadow: rgba(46, 95, 240, 0.4) 5px 5px, rgba(46, 95, 240, 0.3) 10px 10px,
    rgba(46, 85, 240, 0.2) 15px 15px, rgba(46, 69, 240, 0.1) 20px 20px,
    rgba(46, 65, 240, 0.05) 25px 25px;
  transition: 800ms;
}

.portfolio-gallery .video-card {
  background-color: black;
  width: 400px;
  margin: 3rem 1rem 1rem 1rem;
  box-shadow: rgba(46, 95, 240, 0.4) 5px 5px, rgba(46, 95, 240, 0.3) 10px 10px,
    rgba(46, 85, 240, 0.2) 15px 15px, rgba(46, 69, 240, 0.1) 20px 20px,
    rgba(46, 65, 240, 0.05) 25px 25px;
  transition: 800ms;
}

.portfolio-gallery .video-card:hover {
  box-shadow: none;
}

.nametag {
  display: flex;
  justify-content: center;
  max-width: 432px;
  min-height: 114px;
}

.nametag > h2 {
  font-weight: 100;
  font-size: 1.3em;
  margin-bottom: 20px;
  text-align: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.section-title {
  font-size: 30px;
  font-weight: 400;
  margin-top: 60px;
  margin-bottom: 4px;
}

@media screen and (max-width: 1024px) {
  .portfolio-gallery-container {
    flex-direction: column;
  }
  .portfolio-gallery .video-card {
    width: 480px;
  }
  .nametag {
    max-width: 512px;
  }
}

@media screen and (max-width: 767px) {
  .portfolio-gallery .video-card {
    width: 380px;
    margin: 2rem 0;
  }
}

@media screen and (max-width: 460px) {
  .portfolio-gallery .video-card {
    width: 280px;
  }
}

@media screen and (max-width: 350px) {
  .portfolio-gallery .video-card {
    width: 200px;
  }
}
