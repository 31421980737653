.section-2 {
  background-color: rgb(245, 245, 245);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  z-index: -2;
}

.section-2--container {
  width: 95vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.o-nas-section--oferta-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-2--container > h2 {
  font-weight: 100;
  padding: 20px;
  font-size: 30px;
}

.section-2--container > p {
  font-weight: 100;
  padding: 10px;
  font-size: 20px;
}

.o-nas-section--oferta > h2 {
  padding-bottom: 40px;
}

.o-nas-section--oferta {
  margin: 0 60px 0 60px;
  padding: 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.opening-paragraph {
  font-weight: 100;
  font-size: 23px;
  margin: 100px 15px 160px;
  text-align: justify;
}

.opening-paragraph-2 {
  font-weight: 100;
  font-size: 23px;
  padding: 15px 60px 120px;
}

.opening-paragraph-3 {
  max-width: 350px;
  font-weight: 100;
  font-size: 23px;
  padding-left: 70px;
  text-align: justify;
  text-justify: distribute;
}

.oferta-list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.oferta-list-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.oferta-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  min-height: 160px;
  padding: 20px;
}

.oferta-text {
  font-size: 15px;
  font-weight: 100;
  padding: 10px;
  letter-spacing: 2px;
  min-width: 200px;
  min-height: 110px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
}

.oferta-list-row:nth-child(1) {
  transition-delay: 0ms;
}

.oferta-list-row:nth-child(2) {
  transition-delay: 200ms;
}

.oferta-list-row:nth-child(3) {
  transition-delay: 400ms;
}

.o-nas-section--oferta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  padding-bottom: 170px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.o-nas--h2 {
  font-size: 30px;
  font-weight: 400;
  padding: 20px;
  margin-top: 40px;
}

.kaja-photo {
  width: 290px;
  height: auto;
  box-shadow: rgba(240, 75, 46, 0.4) 5px 5px, rgba(240, 98, 46, 0.3) 10px 10px,
    rgba(240, 53, 46, 0.2) 15px 15px, rgba(240, 88, 46, 0.1) 20px 20px,
    rgba(240, 111, 46, 0.05) 25px 25px;
}

@media screen and (max-width: 1210px) {
  .opening-paragraph-2 {
    padding: 25px 60px 100px;
  }
}

@media screen and (max-width: 1024px) {
  .oferta-list-row {
    flex-direction: column;
  }

  .oferta-list-item {
    padding: 40px;
  }

  .oferta-text {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    min-width: 200px;
    min-height: 10px;
  }

  .oferta-list p {
    text-align: center;
  }

  .oferta-icons {
    margin-bottom: 15px;
    margin-right: 0;
  }

  .kaja-photo {
    width: 250px;
  }

  .opening-paragraph-3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .section-2--container > h2 {
    font-weight: 100;
    padding: 10px 0 30px 0;
    font-size: 23px;
  }

  .o-nas-section--oferta-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kaja-photo {
    margin-bottom: 50px;
  }

  .opening-paragraph-3 {
    padding: 10px 70px;
    font-size: 18px;
  }

  .first-paragraph {
    font-size: 18px;
  }

  .section-2--container > p {
    font-weight: 100;
    padding: 5px;
    font-size: 16px;
  }
  .kaja-photo {
    width: 300px;
  }
}

@media screen and (max-width: 680px) {
  .o-nas--p {
    font-size: 15px;
  }

  .o-nas--h2 {
    font-size: 25px;
  }

  .opening-paragraph {
    font-size: 19px;
  }

  .opening-paragraph-2 {
    font-size: 19px;
  }

  .oferta-icons {
    width: 60px;
    height: 60px;
  }

  .oferta-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 540px) {
  .oferta-list {
    width: 70%;
  }
}

@media screen and (max-width: 420px) {
  .o-nas-section--oferta-container {
    width: 100%;
  }

  .first-paragraph {
    padding: 40px;
  }
  .kaja-photo {
    width: 250px;
  }
  .opening-paragraph {
    font-size: 15px;
  }
  .opening-paragraph-2 {
    font-size: 15px;
    padding: 10px 20px 100px;
  }
  .opening-paragraph-3 {
    font-size: 15px;
  }
}

@media screen and (max-width: 340px) {
  .o-nas--p {
    padding: 5px;
  }
  .opening-paragraph-2 {
    font-size: 12px;
  }

  .opening-paragraph-3 {
    font-size: 12px;
  }

  .kaja-photo {
    width: 200px;
  }
}

@media screen and (max-width: 280px) {
  .section-2--container > h2 {
    font-size: 20px;
    padding: 5px;
  }

  .section-2--container > p {
    font-size: 14px;
  }

  .oferta-icons {
    width: 40px;
    height: 40px;
  }

  .oferta-list-item {
    padding: 0 0 10px 0;
  }
}
